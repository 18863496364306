var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { mapActions } from "vuex";
import { possibleApolloErrorToUserMessage } from "../../api/apolloErrorToUserMessage";
export default Vue.extend({
    methods: __assign({ displayPossibleApolloErrorMessage: function (error) {
            this.displayErrorMessage(possibleApolloErrorToUserMessage(error));
        } }, mapActions(["displaySuccessMessage", "displayErrorMessage"])),
});
