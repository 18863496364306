var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import globalCartFragment from "../globalCartFragment.graphql";
import { cartItemMetadataFromObject, } from "./CartItemMetadata";
export default (function (product, metadata) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation createCartMutation($input: CreateCartInput!) {\n      createCart(input: $input) {\n        cart {\n          id\n          ...globalCart\n        }\n      }\n    }\n    ", "\n  "], ["\n    mutation createCartMutation($input: CreateCartInput!) {\n      createCart(input: $input) {\n        cart {\n          id\n          ...globalCart\n        }\n      }\n    }\n    ", "\n  "])), globalCartFragment),
    variables: {
        input: {
            product: product,
            metadata: metadata ? cartItemMetadataFromObject(metadata) : undefined,
        },
    },
}); });
var templateObject_1;
