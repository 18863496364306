import { isApolloError } from "apollo-client";
import toPairs from "lodash/toPairs";
import startCase from "lodash/startCase";
import { isServerError } from "./errors";
function graphQLErrorToUserMessage(error) {
    if ("message" in error) {
        return error.message;
    }
    // This is a custom error format from our backend
    return toPairs(error)
        .map(function (_a) {
        var name = _a[0], messages = _a[1];
        return startCase(name) + ": " + messages.join(", ");
    })
        .join(". ");
}
export function possibleApolloErrorToUserMessage(error) {
    if (!isApolloError(error)) {
        return error.message;
    }
    if (error.networkError) {
        if (isServerError(error.networkError)) {
            var result = error.networkError.result;
            if (error.networkError.statusCode === 400 && Array.isArray(result)) {
                var message = result
                    .map(function (_a) {
                    var errors = _a.errors;
                    return errors.map(graphQLErrorToUserMessage).join(", ");
                })
                    .join(". ");
                return ("There was an error in our system. " +
                    "We're working on fixing it, please try again later (".concat(message, ")"));
            }
        }
        return ("There was a problem communicating with our server. " +
            "Please check your internet connection and try again " +
            "(".concat(error.networkError.message, ")"));
    }
    return error.graphQLErrors.map(graphQLErrorToUserMessage).join(". ");
}
export function rethrowPossibleApolloErrorAsUserMessage(error) {
    throw new Error(possibleApolloErrorToUserMessage(error));
}
